import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

export const ExpandContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp-1);
`;
export const Container = styled("div")`
  padding: var(--sp-4e);

  /* UI scaling... this works because
  every child is using sp-xe variables
  which uses ems */
  @media (min-height: 1440px) {
    font-size: 20px;
  }
  @media (max-height: 900px) {
    font-size: 14px;
  }
  @media (min-width: 3840px) {
    font-size: 28px;
  }

  @media (max-width: 1600px) {
    padding: var(--sp-3e);
  }
  @media (max-width: 1400px) {
    padding: var(--sp-2e);
  }
  @media (min-width: 1921px) {
    padding: var(--sp-6e);
  }

  &.overlay-hidden {
    justify-content: flex-end !important;
    align-items: flex-end !important;

    .card {
      flex: unset;
      width: fit-content;
      padding: var(--sp-2e) var(--sp-2e) 0 var(--sp-2e);
    }
  }

  .card {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--sp-2e);
    padding: var(--sp-6e);
    width: 100%;
    background: var(--shade9);
    border-radius: var(--br-lg);

    @media (max-width: 1500px) {
      padding: var(--sp-4e);
    }
    @media (max-height: 1000px) {
      padding: var(--sp-2e);
    }
    @media (min-width: 1921px) {
      padding: var(--sp-8e);
    }
  }

  .content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(--sp-4e);
    width: 100%;
    max-width: none;
    margin: auto;
    padding-bottom: 0;

    @media (max-height: 1050px) {
      gap: var(--sp-4e);
    }
    @media (max-aspect-ratio: 11 / 16) {
      flex-direction: column;
    }
  }

  .promo-container {
    margin-top: auto;

    &.minimized {
      margin-top: var(--sp-2);

      &:empty {
        margin-top: unset;
      }
    }

    > *:not(:first-child) {
      display: none;
    }
  }

  .visibility-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    gap: var(--sp-2e);
    padding: 0;
    background: transparent;
    color: var(--shade2);
    cursor: pointer;

    &.minimize {
      margin-left: unset;
      gap: var(--sp-5);
      padding: 0 var(--sp-2_5);
    }

    &:hover {
      color: var(--shade0);
    }

    svg {
      width: var(--sp-5e);
      height: var(--sp-5e);
    }
  }
`;
export const Teams = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--sp-6e);

  > * {
    flex: 1;
  }

  @media (max-height: 1050px) {
    gap: var(--sp-3e);
  }

  .overlay-hidden & {
    display: none;
  }
`;

export const RightCol = styled("div")`
  display: grid;
  grid-auto-rows: max-content;
  gap: var(--sp-6e);
  width: 20%;
  max-width: 22em;
  flex-shrink: 0;

  @media (max-height: 1050px) {
    gap: var(--sp-2e);
  }

  @media (min-aspect-ratio: 2 / 1) and (min-width: 2560px) {
    flex-basis: 25%;
  }
  @media (min-width: 3840px) {
    flex-basis: 25%;
  }

  .overlay-hidden & {
    display: none;
  }

  .video-ad-container {
    margin-top: auto;
    padding-bottom: var(--sp-1e);
  }

  .video-ad-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--sp-10e);

    .ad-text {
      text-transform: uppercase;
      color: var(--shade0);
      margin: 0 var(--sp-2e);
    }

    .cta-text {
      color: var(--shade2);
      cursor: pointer;
      border-radius: var(--sp-2e);

      &:hover {
        color: var(--shade0);
      }
    }

    .upgrade-cta {
      color: var(--subscriber-solid);
      font-weight: 700;
    }
  }
`;
export const TabGrp = styled("div")`
  --bg: var(--shade7);
  --bg-inactive: var(--shade8);
  display: grid;
  gap: var(--sp-3);

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--sp-2);
  }
  button {
    color: var(--shade0);
    height: var(--sp-10e);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer;
    background: var(--shade5-75);
    border-radius: var(--br);

    @media (max-height: 1050px) {
      height: var(--sp-8e);
    }

    &[disabled] {
      opacity: 0.38;
      pointer-events: none;
    }

    &:not(.active) {
      color: var(--shade2);
      background: var(--shade7);
    }
  }
  .panel {
    display: flex;
    flex-direction: column;
    gap: var(--sp-4e);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media (max-height: 1050px) {
      padding: var(--sp-4e);
    }
  }
`;

export const LaneMatchups = styled(Card)`
  padding: var(--sp-4e);

  ol {
    display: flex;
    flex-direction: column;
    gap: var(--sp-4e);

    @media (max-height: 950px) {
      gap: var(--sp-3e);
    }
  }

  .matchup {
    display: flex;
    align-items: center;

    &[data-no-matchup="true"] {
      opacity: 0.5;
    }
    &[data-winning-teammate="true"] {
      .val-right,
      .champ-img.opponent {
        opacity: 0.75;
      }
    }
    &[data-winning-teammate="false"] {
      .val-left,
      .champ-img.teammate {
        opacity: 0.75;
      }
    }
  }
  .champ-img {
    position: relative;
    width: var(--sp-8e);
    height: var(--sp-8e);
    border-radius: 50%;
    z-index: 1;

    @media (max-height: 950px) {
      width: var(--sp-6e);
      height: var(--sp-6e);
    }

    &.teammate {
      box-shadow: 0 0 0 2px var(--c1);
    }
    &.opponent {
      box-shadow: 0 0 0 2px var(--c2);
    }
  }
  .values {
    --text-buffer: var(--sp-5e);
    position: relative;
    flex: 1;
    display: flex;

    .val {
      position: relative;
      height: var(--sp-1_5e);

      span {
        position: absolute;
        top: var(--sp-3e);
      }
    }
    .val-left {
      text-align: right;
      color: var(--c1);
      background: var(--c1);

      span {
        right: 0;
        padding-right: var(--text-buffer);
      }
    }
    .val-right {
      color: var(--c2);
      background: var(--c2);

      span {
        padding-left: var(--text-buffer);
      }
    }
  }
  .role-icon {
    position: absolute;
    top: 50%;
    left: calc(var(--v1) * 100%);
    padding: var(--sp-1e);
    background: var(--bg, var(--shade-8));
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--winner);
    transform: translate(-50%, -50%);

    svg {
      width: var(--sp-4e);
      height: var(--sp-4e);
      fill: var(--winner);

      @media (max-height: 950px) {
        width: var(--sp-3e);
        height: var(--sp-3e);
      }
    }
  }
`;

export const LoadingContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 5rem;
    height: 5rem;
  }
`;
