import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_DEV } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import {
  cssInGameContainer,
  cssPlayerList,
} from "@/game-lol/components/in-game-template.style.mjs";
import { cssDmgBreakdown } from "@/game-lol/components/in-game-template-damage.style.mjs";
import { cssPlayerCard } from "@/game-lol/components/in-game-template-summoner.style.mjs";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import {
  MAX_TAGS,
  PREMADE_LABELS,
  RANK_SYMBOLS,
  ROLE_INDEX,
  ROLE_SYMBOLS,
  TEAMS,
} from "@/game-lol/constants/constants.mjs";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants-queue-symbols.mjs";
import LoadingScreenRefs from "@/game-lol/LoadingScreen.refs.jsx";
import {
  Container,
  ExpandContainer,
  LaneMatchups,
  RightCol,
  TabGrp,
  Teams,
} from "@/game-lol/LoadingScreen.style.jsx";
import {
  getLoadingOverlayMinimized,
  updateLoadingOverlayMinimized,
} from "@/game-lol/utils/actions.mjs";
import championProficiency from "@/game-lol/utils/get-champion-proficiency.mjs";
import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import {
  mapLoadingScreenData,
  playstyles,
} from "@/game-lol/utils/in-game-util.mjs";
import {
  createPlaystyleTags,
  renderedTags,
} from "@/game-lol/utils/in-game-vanity-tags.mjs";
import rolesStats from "@/game-lol/utils/roles-stats.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import SymbolRole from "@/game-lol/utils/symbol-role.mjs";
import {
  aggregatePlayerChampions,
  calculateTeamDamages,
  damageBreakdownUI,
  getStaticData,
  getWinRateColor,
  isLoadingScreenQueue,
  isRoleQueue,
  mapRoleToSymbol,
  playerChampionStats,
  playstylePremades,
} from "@/game-lol/utils/util.mjs";
import BlitzLogo from "@/inline-assets/blitz-logo-bolt.svg";
import CheckMarkIcon from "@/inline-assets/circle-check.svg";
import Hide from "@/inline-assets/hide-visibility.svg";
import { useReRender } from "@/shared/EventedRender.jsx";
import { classNames } from "@/util/class-names.mjs";
import { appLog, devLog } from "@/util/dev.mjs";
// import { appLog } from "@/util/dev.mjs";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";
import useDOMElement from "@/util/use-dom-element.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";
import useKeypress from "@/util/use-key-press.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const HIDE_MATCHUPS = false;

const TabBtn = (props) => {
  const { active, onClick, disabled } = props;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={active ? "active type-callout--bold" : "type-callout--semi"}
    >
      {props.children}
    </button>
  );
};

function LobbyDamageBreakdown({
  enemyTeamId,
  teammatesRendered,
  enemyTeamRendered,
}) {
  const { t } = useTranslation();
  const {
    volatile: { LoLLoadingState = {} },
  } = useSnapshot(readState);

  const { championsStats = [] } = LoLLoadingState;

  if (!championsStats.length) return null;

  return (
    <TabGrp>
      <div className="panel">
        <Damage
          showAlert
          className={
            enemyTeamId === TEAMS.BLUE.id ? "lower-team" : "upper-team"
          }
          title={t(
            "lol:abilities.teamDamageBreakdown",
            "Team Damage Breakdown",
          )}
          roster={teammatesRendered}
        />
        <Damage
          showAlert
          className={
            enemyTeamId === TEAMS.BLUE.id ? "upper-team" : "lower-team"
          }
          title={t(
            "lol:abilities.enemyDamageBreakdown",
            "Enemy Damage Breakdown",
          )}
          roster={enemyTeamRendered}
        />
      </div>
    </TabGrp>
  );
}

const LaneBreakdown = (props) => {
  const { enemyTeam, teammates } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    volatile: { LoLLoadingState = {} },
  } = useSnapshot(readState);

  const { championsStats = [] } = LoLLoadingState;

  // First index is important, if no role was requested BE returns all roles
  // the first being the most common
  const [statKey, setStatKey] = useState("lane_win_rate");

  const sendInteractionEvent = useInteractionEvent("loading-screen");

  const headToHead = useMemo(() => {
    if (!championsStats.length) return [];
    return teammates
      .filter((t) => t?.championId && t?.role)
      .map((teammate) => {
        const opponentId = (enemyTeam || []).find(
          (enemy) => enemy?.role === teammate.role,
        )?.championId;
        const championStats = championsStats.find(
          (c) => c.championId === teammate.championId,
        );

        const matchupStats = (championStats?.matchups || []).find(
          (matchup) => matchup.matchup_champion_id === opponentId,
        );

        return {
          role: matchupStats?.individual_position,
          teammateId: teammate.championId,
          opponentId,
          matchupStats,
        };
      });
  }, [championsStats, teammates, enemyTeam]);

  const displayStats = useMemo(() => {
    return headToHead
      .sort(
        (a, b) =>
          ROLE_INDEX[SymbolRole(a.role)] - ROLE_INDEX[SymbolRole(b.role)],
      )
      .map((matchup) => {
        const { role, teammateId, opponentId, matchupStats } = matchup;
        const teammateStat = matchupStats ? matchupStats[statKey] : null;
        const opponentStat = teammateStat !== null ? 1 - teammateStat : null;

        return {
          hasMatchup: Boolean(matchupStats),
          role,
          teammateId,
          teammateStat,
          opponentId,
          opponentStat,
        };
      });
  }, [statKey, headToHead]);

  if (!championsStats.length || !headToHead.length || HIDE_MATCHUPS)
    return null;

  return (
    <LaneMatchups>
      <TabGrp>
        <div className="buttons">
          <TabBtn
            active={statKey === "lane_win_rate"}
            onClick={() => {
              setStatKey("lane_win_rate");
              sendInteractionEvent("winrate-widget", {
                tab: "lane",
              });
            }}
          >
            {t("lol:stats.laneWinRate", "Lane Win Rate")}
          </TabBtn>
          <TabBtn
            active={statKey === "win_rate"}
            onClick={() => {
              setStatKey("win_rate");
              sendInteractionEvent("winrate-widget", {
                tab: "game",
              });
            }}
          >
            {t("lol:stats.gameWinRate", "Game Win Rate")}
          </TabBtn>
        </div>
        <div className="panel">
          <ol>
            {displayStats.map((lane, i) => {
              const RoleIcon = lane.role && getRoleIcon(lane.role);
              const teammateWins = lane.teammateStat >= lane.opponentStat;

              return (
                <li
                  key={`${lane.teammateId}:${i}`}
                  data-winning-teammate={teammateWins}
                  data-no-matchup={lane.teammateStat === null}
                  className="matchup"
                  style={{
                    "--c1":
                      lane.teammateStat !== null && teammateWins
                        ? "var(--blue)"
                        : "var(--shade3)",
                    "--c2":
                      lane.teammateStat !== null && !teammateWins
                        ? "var(--red)"
                        : "var(--shade3)",
                    "--winner":
                      lane.teammateStat && teammateWins
                        ? "var(--c1)"
                        : "var(--c2)",
                  }}
                >
                  <img
                    src={Static.getChampionImage(lane.teammateId)}
                    height="32"
                    width="32"
                    className="champ-img teammate"
                  />
                  <div
                    className="values"
                    style={{
                      "--v1": lane.teammateStat ?? 0.5,
                      "--v2": lane.opponentStat ?? 0.5,
                    }}
                  >
                    <div
                      className="val val-left"
                      style={{ flex: lane.teammateStat ?? 0.5 }}
                    >
                      <span className="type-caption--bold">
                        {lane.teammateStat &&
                          formatToPercent(language, lane.teammateStat, {
                            min: 1,
                            max: 1,
                          })}
                      </span>
                    </div>
                    <div
                      className="val val-right"
                      style={{ flex: lane.opponentStat ?? 0.5 }}
                    >
                      <span className="type-caption--bold">
                        {lane.opponentStat &&
                          formatToPercent(language, lane.opponentStat, {
                            min: 1,
                            max: 1,
                          })}
                      </span>
                    </div>
                    <div className="role-icon">{RoleIcon && <RoleIcon />}</div>
                  </div>
                  <img
                    src={Static.getChampionImage(lane.opponentId)}
                    height="32"
                    width="32"
                    className="champ-img opponent"
                  />
                </li>
              );
            })}
          </ol>
        </div>
      </TabGrp>
    </LaneMatchups>
  );
};

const Damage = memo((props) => {
  const { roster, title, frameless = false, showAlert = false } = props;
  const {
    volatile: { LoLLoadingState = {} },
  } = useSnapshot(readState);

  const { championsStats = [] } = LoLLoadingState;

  if (!championsStats.length) return null;

  const { apDamageShare, adDamageShare, trueDamageShare } =
    calculateTeamDamages(roster, championsStats);
  const {
    alert,
    apColor,
    adColor,
    trueColor,
    adPercentText,
    apPercentText,
    truePercentText,
  } = damageBreakdownUI({ apDamageShare, adDamageShare, trueDamageShare });

  if (!apDamageShare || !adDamageShare || !trueDamageShare) return;

  return (
    <div
      className={cssDmgBreakdown()}
      data-frameless={frameless}
      data-is-alert={showAlert && alert}
    >
      {title && <p className="type-caption title">{title}</p>}
      <div className="flex type-title--bold">
        <div
          className="flex-item"
          style={{
            flex: apDamageShare,
            "--dmg-color": apColor,
          }}
        >
          <div className="bar">{apPercentText}</div>
        </div>
        <div
          className="flex-item"
          style={{
            flex: adDamageShare,
            "--dmg-color": adColor,
          }}
        >
          <div className="bar">{adPercentText}</div>
        </div>
        <div
          className="flex-item"
          style={{
            flex: trueDamageShare || 0,
            "--dmg-color": trueColor,
          }}
        >
          <div className="bar">{truePercentText}</div>
        </div>
      </div>
    </div>
  );
});
Damage.displayName = "Damage";

const Premades = memo(({ derivedId, premadeParties = {} }) => {
  const { t } = useTranslation();

  const playerParty = premadeParties[derivedId];

  if (!playerParty) return null;

  const label = PREMADE_LABELS[playerParty.partySize];

  return (
    <div
      className="premade"
      data-size={playerParty.partySize}
      data-party={playerParty.partyId}
    >
      {new Array(playerParty.partySize).fill(0).map((_, i) => (
        <span key={i} className="dot"></span>
      ))}
      {label && <span className="type-caption--bold label">{t(...label)}</span>}
    </div>
  );
});
Premades.displayName = "Premades";

const BackgroundImage = memo(({ championId, skinId }) => {
  const [bgImg, setBgImg] = useState(
    Static.getChampionCenterSkinImage(championId, skinId),
  );

  useEffect(() => {
    setBgImg(Static.getChampionCenterSkinImage(championId, skinId));
  }, [championId, skinId]);

  function handleBgImgError(ev) {
    const defaultImg = Static.getChampionCenterSkinImage(championId);
    if (ev.target.src !== defaultImg) setBgImg(defaultImg);
  }

  return (
    <div className="bg-img-container">
      <img src={bgImg} onError={handleBgImgError} className="bg-img" />
    </div>
  );
});
BackgroundImage.displayName = "BgImage";

const Player = ({
  player = {},
  isSR,
  isTeammate,
  tagsEnabled,
  premadeParties = {},
}) => {
  const { t } = useTranslation();
  const {
    volatile: { LoLLoadingState = {} },
  } = useSnapshot(readState);

  const {
    championId,
    skinId,
    gameName,
    derivedId,
    playstyles,
    mastery,
    runes = [],
    spells = [],
    roleSvg,
  } = player;

  const [spell1Id, spell2Id] = spells;
  const role = player.role && mapRoleToSymbol(player.role);

  const dmgRoster = [{ championId, role }];

  const hasPosition = !!role;
  const hasRunes = !!runes.length;
  const hasSummonerSpells = spell1Id && spell2Id;

  const champions = getStaticData("champions");
  const champion =
    Object.values(champions || []).find((c) => Number(c.key) === championId) ||
    CHAMPIONS[championId];
  const championName = champion?.name || champion?.key || "";

  // Player ranked stats
  const summonerRanks = player?.account?.latestRanks || [];
  const _currentQueueStats = summonerRanks.find(
    (q) => QueueSymbol(q.queue) === QueueSymbol(LoLLoadingState.queueId),
  );
  const rankedSoloDuoStats = summonerRanks.find(
    (q) => QueueSymbol(q.queue) === QUEUE_SYMBOLS.rankedSoloDuo,
  );
  const queueStats = rankedSoloDuoStats || {};
  const summonerTier = queueStats.tier;
  const summonerTierIcon =
    summonerTier && getHextechRankIcon(summonerTier, true);

  // Player champion stats
  const aggregateChampionStats = aggregatePlayerChampions(player.championStats);
  const mostplayedChampions = Object.values(aggregateChampionStats)
    .filter((c) => c.championId)
    .sort((a, b) => b.games - a.games);
  const championStats = playerChampionStats(aggregateChampionStats, championId);
  const tags = playstyles
    ? createPlaystyleTags(playstyles, role, championId, isSR)
    : [];
  const wrColor =
    championStats.games &&
    getWinRateColor((championStats.wins / championStats.games) * 100);
  const kdaColor = championStats.games && kdaColorStyle(championStats.kda);
  const champProficiency = championProficiency(
    championId,
    championName,
    playstyles,
  );
  const roleProficiency = rolesStats(role, playstyles);

  const championLevel = mastery?.[championId]?.championLevel;

  const isBlitzUser = !!LoLLoadingState.blitzUsers[derivedId];

  if (!championId) {
    return <div className={cssPlayerCard()} />;
  }

  return (
    <div
      className={cssPlayerCard()}
      data-player={true}
      data-in-progress="false"
      data-is-teammate={!!isTeammate}
      data-has-position={hasPosition}
      data-has-runes={hasRunes}
      data-has-summoner-spells={hasSummonerSpells}
      style={{
        "--wr-color": wrColor,
        "--kda-color": kdaColor,
        order: ROLE_INDEX[role],
      }}
    >
      <BackgroundImage championId={championId} skinId={skinId} />
      <Premades derivedId={derivedId} premadeParties={premadeParties} />
      <div className="most-played">
        {mostplayedChampions.slice(0, 3).map((c) => {
          return (
            <img
              key={c.championId}
              src={Static.getChampionImage(c.championId)}
              width="20"
              height="20"
            />
          );
        })}
      </div>
      <div className={`rank-border tier-${summonerTier}`}>
        {summonerTierIcon && (
          <div className="rank-icon" data-place="bottom">
            <div dangerouslySetInnerHTML={{ __html: summonerTierIcon }} />
            <span className="type-caption--bold">
              {[
                RANK_SYMBOLS.challenger,
                RANK_SYMBOLS.grandmaster,
                RANK_SYMBOLS.master,
              ].includes(queueStats.tier)
                ? queueStats.leaguePoints
                : queueStats.rank}
            </span>
            <span className="type-caption--bold">
              {t(
                "lol:matchHistory.winsAndLossesWithHypen",
                "{{wins}}W-{{losses}}L",
                {
                  wins: queueStats.wins,
                  losses: queueStats.losses,
                },
              )}
            </span>
          </div>
        )}
      </div>
      <div className="name">
        <span className="player-name type-subtitle1">
          {isBlitzUser && (
            <span className="using-blitz">
              <BlitzLogo />
            </span>
          )}
          <span className="text">
            {gameName || t("common:player", "Player")}
          </span>
        </span>
        <span className="champion-name type-mini">{championName || ""}</span>
      </div>
      <div className="info">
        <div className="summoner-spells">
          <img
            src={Static.getSummonerSpellImageById(spell1Id)}
            width="20"
            height="20"
          />
          <img
            src={Static.getSummonerSpellImageById(spell2Id)}
            width="20"
            height="20"
          />
        </div>
        <div className="champ-container">
          <img
            src={Static.getChampionImage(championId)}
            width="48"
            height="48"
            className="champ-img"
          />
          {roleSvg && role !== ROLE_SYMBOLS.all && isSR ? (
            <div
              className="player-role"
              data-place="top"
              data-tip={roleProficiency.tooltip}
            >
              <div
                className="role-icon"
                dangerouslySetInnerHTML={{ __html: roleSvg }}
              />
              {roleProficiency.icon && (
                <div
                  className="role-proficiency"
                  dangerouslySetInnerHTML={{
                    __html: roleProficiency.icon,
                  }}
                />
              )}
            </div>
          ) : null}
          {isSR && champProficiency.svg ? (
            <div
              className="champion-proficiency"
              data-tip={champProficiency.tooltip}
              dangerouslySetInnerHTML={{ __html: champProficiency.svg }}
            />
          ) : null}
        </div>
        <div className="runes">
          <span className="rune keystone">
            <img src={Static.getRuneImage(runes[0])} width="24" height="24" />
          </span>
          <span className="rune secondary-tree">
            <img src={Static.getRuneImage(runes[1])} width="12" height="12" />
          </span>
        </div>
      </div>
      {tagsEnabled && (
        <div className="tags">
          {renderedTags(tags)
            .slice(0, MAX_TAGS)
            .map((tag, i) => (
              <div
                key={`${tag.tagText}:${i}`}
                data-tip={tag.tagTooltip}
                className={tag.tagClass}
              >
                <span className="type-caption--bold text">{tag.tagText}</span>
              </div>
            ))}
        </div>
      )}
      <div className="stats-blocks" data-hide={!player.championStats.length}>
        <div
          className="champion-mastery type-caption--semi"
          data-has-points={!!championLevel}
        >
          {t("lol:championMastery.masteryLevel", "Mastery {{level}}", {
            level: championLevel,
          })}
        </div>
        <div className="stat-block" data-no-stats={!championId}>
          <div className="champion">
            <img
              src={Static.getChampionImage(championId)}
              width="24"
              height="24"
              loading="lazy"
              className="champ-img"
            />
          </div>
          <div className="winrate">
            <span
              className="stat stat-val type-caption--bold"
              data-highlight={Boolean(
                championStats.wins / (championStats.games || 1) >= 0.7 &&
                  championStats.games > 5,
              )}
            >
              {(championStats.wins / (championStats.games || 1)).toLocaleString(
                getLocale(),
                {
                  style: "percent",
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                },
              )}
            </span>
            <span
              className="stat type-caption"
              data-highlight={Boolean(championStats.games >= 40)}
            >
              {t("common:stats.gamesCountPlayed", "{{count}} Played", {
                count: championStats.games.toLocaleString(getLocale()),
              })}
            </span>
          </div>
          <div className="kda">
            <span className="stat stat-val type-caption--bold">
              {championStats.games
                ? t("lol:matchTile.kda", "{{kda}} KDA", {
                    kda: championStats.kda.toLocaleString(getLocale(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })
                : "-"}
            </span>
            <span className="stat type-caption">
              {t("lol:displayKDA", "{{kills}} / {{deaths}} / {{assists}}", {
                kills: (
                  championStats.kills / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                deaths: (
                  championStats.deaths / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                assists: (
                  championStats.assists / (championStats.games || 1)
                ).toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="damage" data-hide={!championId}>
        <Damage hide={!championId} roster={dmgRoster} frameless={true} />
      </div>
    </div>
  );
};

const LoadingScreen = () => {
  const { t } = useTranslation();
  const {
    volatile: { LoLLoadingState, LoLLiveClient = {}, lolStaticDataLoaded },
  } = useSnapshot(readState);

  const {
    queueId,
    region,
    playersState = {},
    lcuLocalPlayer,
  } = LoLLoadingState || {};
  const localPlayerSummonerId = lcuLocalPlayer?.summonerId;

  const [hidden, setHidden] = useState(getLoadingOverlayMinimized());

  const adElement = useDOMElement(LoadingScreenRefs.videoAd);
  const battlesPromoElement = (
    <Suspense key="battles-promo">
      <LoadingScreenRefs.battlesPromo
        LoLLiveClient={LoLLiveClient}
        minimized={hidden}
      />
    </Suspense>
  );
  const premiumPromoElement = !adElement ? (
    <Suspense key="premium-promo">
      <LoadingScreenRefs.premiumPromo />
    </Suspense>
  ) : null;
  const promoElements = [battlesPromoElement, premiumPromoElement].filter(
    Boolean,
  );

  useReRender();

  const sendInteractionEvent = useInteractionEvent("loading-screen");

  const openProPage = useCallback(() => {
    blitzMessage(EVENTS.OPEN_PRO_PAGE);
    sendInteractionEvent("open-pro-page");
  }, [sendInteractionEvent]);

  // Just adding the ability to override the queue in dev for debugging
  const queue = QueueSymbol(queueId);
  const isSR = isRoleQueue(queue);

  const TAGS_ENABLED =
    queue === QUEUE_SYMBOLS.rankedSoloDuo ||
    queue === QUEUE_SYMBOLS.normalDraft;

  const playerCount = Object.keys(
    playersState?.accountsBySummonerId || {},
  ).length;

  const { teammatesRendered, enemyTeamRendered } = useMemo(() => {
    let team = [];
    let enemy = [];

    void lolStaticDataLoaded;
    void playerCount;

    if (typeof playersState.teamOne === "object") {
      const teamOne = Object.values(playersState.teamOne).map((p) => ({
        ...p,
        team: TEAMS.BLUE.riot,
      }));
      const teamTwo = Object.values(playersState.teamTwo).map((p) => ({
        ...p,
        team: TEAMS.RED.riot,
      }));

      if (teamOne.find((p) => p.summonerId === localPlayerSummonerId)) {
        team = teamOne;
        enemy = teamTwo;
      } else {
        team = teamTwo;
        enemy = teamOne;
      }
    }

    const teammatesRendered = mapLoadingScreenData(
      team,
      false,
      playersState,
      LoLLiveClient,
    );
    const enemyTeamRendered = mapLoadingScreenData(
      enemy,
      true,
      playersState,
      LoLLiveClient,
    );

    return {
      teammatesRendered,
      enemyTeamRendered,
    };
  }, [
    playersState,
    LoLLiveClient,
    localPlayerSummonerId,
    lolStaticDataLoaded,
    playerCount,
  ]);

  devLog("loadingscreen teammatesRendered", teammatesRendered);
  devLog("loadingscreen enemyTeamRendered", enemyTeamRendered);

  const premadeParties = useMemo(() => {
    const { riotDerivedIdBySummonerId = {}, playStylesBySummonerId = {} } =
      playersState;

    const playersPlaystyles = playstyles({
      players: Object.keys(riotDerivedIdBySummonerId),
      playstylesData: playStylesBySummonerId,
      derivedIds: riotDerivedIdBySummonerId,
      region,
    });

    const premades = playstylePremades(playersPlaystyles);

    return premades;
  }, [playersState, region]);

  const enemyTeamId =
    enemyTeamRendered[0]?.teamId === TEAMS.BLUE.int
      ? TEAMS.RED.riot
      : TEAMS.BLUE.riot;

  if (LoLLoadingState && !teammatesRendered.length) {
    appLog("[LoL] Loading screen missing players");
  }
  if (!isLoadingScreenQueue(queue) || !teammatesRendered.length) {
    return null;
  }

  return (
    <Container
      style={{ "--players-per-team": teammatesRendered.length }}
      {...classNames(
        cssInGameContainer(),
        "overlay",
        hidden && "overlay-hidden",
      )}
    >
      <div className="card">
        <ExpandCollapse
          hidden={hidden}
          setHidden={setHidden}
          promoElements={promoElements}
        />
        <div className="content">
          <Teams>
            <div
              {...classNames(
                cssPlayerList(),
                enemyTeamId === TEAMS.BLUE.id ? "lower-team" : "upper-team",
              )}
            >
              {teammatesRendered.map((player, index) => {
                return (
                  <Player
                    isTeammate
                    key={`teammate:${player?.championId}:${index}`}
                    index={index}
                    player={player}
                    isSR={isSR}
                    tagsEnabled={TAGS_ENABLED}
                    premadeParties={premadeParties}
                  />
                );
              })}
            </div>
            <div
              {...classNames(
                cssPlayerList(),
                enemyTeamId === TEAMS.BLUE.id ? "upper-team" : "lower-team",
              )}
            >
              {enemyTeamRendered.map((player, index) => {
                return (
                  <Player
                    key={`opponent:${player?.championId}:${index}`}
                    index={index}
                    player={player}
                    isSR={isSR}
                    tagsEnabled={TAGS_ENABLED}
                    premadeParties={premadeParties}
                  />
                );
              })}
            </div>
          </Teams>
          <RightCol>
            <LobbyDamageBreakdown
              enemyTeamId={enemyTeamId}
              teammatesRendered={teammatesRendered}
              enemyTeamRendered={enemyTeamRendered}
            />
            {isRoleQueue(queue) ? (
              <LaneBreakdown
                enemyTeam={enemyTeamRendered}
                teammates={teammatesRendered}
              />
            ) : null}
            {promoElements.length ? (
              <div className="promo-container">
                {promoElements.map((element) => element)}
              </div>
            ) : null}
            {adElement && (
              <div className="video-ad-container">
                <div className="video-ad-header">
                  <span className="type-overline ad-text">
                    {t("ads:advertisement", "Advertisement")}
                  </span>
                  <button className="visibility-btn" onClick={openProPage}>
                    <span className="cta-text type-caption--bold">
                      <Trans i18nKey="ads:removeAds">
                        Remove Ads -{" "}
                        <span id="upgrade-cta">Upgrade to Premium</span>
                      </Trans>
                    </span>
                  </button>
                </div>
                {adElement}
              </div>
            )}
          </RightCol>
        </div>
      </div>
    </Container>
  );
};

function ExpandCollapse({ hidden, setHidden, promoElements }) {
  const { t } = useTranslation();
  const {
    volatile: { LoLLoadingState, LoLLiveClient },
  } = useSnapshot(readState);

  const toggleHidden = useCallback(
    (e) => {
      e?.preventDefault();
      setHidden((oldHidden) => {
        const newHidden = !oldHidden;
        updateLoadingOverlayMinimized(newHidden);
        return newHidden;
      });
    },
    [setHidden],
  );

  useKeypress("Space", toggleHidden);
  handleMessage(EVENTS.LOL_TOGGLE_LOADING_VISIBILIY, () => {
    toggleHidden();
  });

  return (
    <ExpandContainer>
      {IS_DEV && (
        <>
          <CheckMarkIcon
            width="16"
            height="16"
            style={{
              color: !LoLLoadingState ? "var(--shade6)" : "var(--turq)",
            }}
          />
          <CheckMarkIcon
            width="16"
            height="16"
            style={{ color: !LoLLiveClient ? "var(--shade6)" : "var(--turq)" }}
          />
        </>
      )}
      <button
        {...classNames(
          "visibility-btn",
          "type-form--button",
          hidden && "minimize",
        )}
        onClick={toggleHidden}
      >
        <Hide />
        <span>
          {hidden
            ? t("common:spaceToShowStats", "Press Space to Show Stats")
            : t("common:spaceToHideStats", "Press Space to Hide Stats")}
        </span>
      </button>
      <div className="promo-container minimized">
        {hidden ? promoElements.map((element) => element) : null}
      </div>
    </ExpandContainer>
  );
}

export function meta() {
  return {
    title: [null, "League of Legends - Loading Screen"],
    description: [null, "League of Legends Loading Screen Overlay"],
  };
}

export default LoadingScreen;
